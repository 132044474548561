export const TRANSLATION_BG = {
    DATE: {
        long: 'DD MMMM YYYY [-] HH:mm',
        date: 'DD-MM-YYYY',
    },
    LOGIN: {
        title: 'Добре дошли!',
        slogan: 'Бъдете внимателни! Безопасността е на първо място!',
        buttons: {
            external: 'Аз съм служител на трета страна',
            internal: 'Аз съм служител на ETT',
        },
    },
    BUTTONS: {
        remove: 'Изтрий',
        ok: 'OK',
        yes: 'Да',
        yes_and_send: 'Потвърди и изпрати',
        no: 'Не',
        cancel: 'Отказ',
        again: 'Отново',
        back: 'Назад',
        submit: 'Изпрати',
        skip: 'Пропусни',
        next: 'Напред',
    },
    DASHBOARD: {
        tile: {
            home: 'Начало',
            forms: 'Формуляри',
            compliment: 'Изказване на служебна благодарност',
            compliment_desc: 'Искам да похваля колега за безопасната работа.',
            scan_qr: 'Сканирайте QR',
            scan_qr_desc: 'Искам QR код, за да мога да отида до правилната форма.',
            instruction: 'Програма WAVE',
            more: 'Още',
            title: 'Здравей, какво ще правиш?',
            subtitle: 'Моля, направете избор по-долу',
        },
        report: {
            incident: 'Докладване за инцидент или основни рискове',
            incident_desc: 'Искам да съобщя за инцидент, опасна практика, среда и/или експлозия или пожар.',
        },
        tasks: {
            notification: {
                singular: 'You have <b>{{value}} open</b> task',
                plural: 'You have <b>{{value}} open</b> tasks',
            },
        },
    },
    DASHBOARD_MORE: {
        tile: {
            history: 'Хронология',
            news: 'Новини',
            cardGame: 'WAVE ускорител',
            tasks: 'Задачи',
        },
        tasks: {
            notification: {
                singular: '{{value}} open task',
                plural: '{{value}} open tasks',
            },
        },
    },
    HEADER: {
        structure: 'New form',
    },
    PUSH: {
        news: {
            title: 'Получаване на новина',
        },
        task: {
            title: 'Задачата е възложена',
        },
        instruction: {
            title: 'Получаване на програма WAVE',
        },
    },
    DISCLAIMER: {
        heading: 'ОТКАЗ ОТ ОТГОВОРНОСТ',
        title: 'Важно:',
        content: 'Не въвеждайте лични данни (като имена, тел. номера, ДК № на автомобили), ако не сме ги поискали изрично. ' +
            'Ако трябва да се качи снимка, погрижете се лицата на изображението да не могат да бъдат разпознати.',
        buttons: {
            agree: 'Да, прочетох отказа от отговорност.',
        },
    },
    HELP: {
        title: 'Помощен център',
        app_version: 'Версия на приложението:',
        intro: 'По-долу са изброени най-често задаваните въпроси за приложението WAVE. Ако не намирате отговор на въпроса си тук, обърнете се към помощния център по ИТ във Вашата компания.',
        phone_number: 'Телефонен номер',
        tenant_phone_numbers: {
            'be36ab0a-ee39-47de-9356-a8a501a9c832': '088 186 0606', // VWT
            '0ef544fb-3d5c-4c60-932e-735bcef00405': '088 186 7777', // BVGO
            'cdc477bf-b6e3-4345-b1be-3b225394e17e': '088 186 2555', // VW Infra
            '957801e8-a608-42e6-afc7-a2e6399ec203': '085 800 1221', // V&SH
        },
        buttons: {
            privacy: 'Заявление за поверителност',
            instruction_film: 'Учебен видеоклип',
        },
    },
    CONTACT: {
        title: 'Данни за връзка',
        subtitle: 'See your account information, log out or change the app language.',
        email_address: 'Имейл адрес',
        phone_number: 'Телефонен номер',
        fill_phone_number: 'Въведете Вашия телефонен номер',
        language_selection: 'Избор на език',
        save: 'Записване на промените',
        logout: 'Излизане',
        change_password: 'Смяна на парола',
        remove_account: 'Изтриване на акаунт',
        confirm_external_modal: {
            heading: 'Изтриване на акаунт',
            body: 'Наистина ли искате да изтриете акаунта?',
            button: 'Потвърди и изтрий акаунта',
        },
        removing_account: 'Изтриване на акаунта',
        account_removed: 'Акаунтът Ви е изтрит успешно.',
    },
    DEEPLINK_QUESTIONNAIRE: {
        title: 'Отворете формуляр',
        unfortunately: 'За съжаление...',
        ok_and_close: 'OK и затвори страницата',
    },
    EXTERNAL_LOGIN: {
        title: 'Външен акаунт',
        email: 'Имейл',
        password: 'Парола (8 или повече знака)',
        login: 'Влизане',
        forgot_password: 'Забравена парола',
        create_account: 'Създаване на акаунт',
    },
    FAQ: {
        title: 'ЧЗВ',
        sections: [{
            title: '',
            questions: [{
                question: 'Към кого да се обърна с въпроси за формулярите?',
                answer: 'За въпроси по съдържанието на формулярите в приложението можете да се обърнете към функционалния администратор (обикновено колега, отговарящ за качество, здраве, безопасност и околна среда (КЗБОС), или експерт по безопасността) от Вашата компания.',
                open: false,
            }, {
                question: 'Какво да правя, ако не мога да отговоря на всички въпроси?',
                answer: 'Разбира се, може да не знаете отговорите на всички въпроси. Отговаряйте само когато сте сигурни. В противен случай отбележете, че не знаете отговора.',
                open: false,
            }, {
                question: 'Към кого да се обърна с технически въпроси за приложението?',
                answer: 'За технически въпроси за приложението се обърнете към помощния център по ИТ във Вашата компания. Телефонният номер е посочен в горната част на страницата.',
                open: false,
            }, {
                question: 'Какво означава цифрата в оранжево под „Формуляри“?',
                answer: 'На таблото Ви се изписва цифра, когато един или повече формуляри не са били попълнени и/или изпратени. Цифрата показва колко такива формуляра има. ' +
                    'Можете да намерите формулярите, които не са били попълнени и/или изпратени, в долната част на страницата с формулярите. Като щракнете върху тях, можете да ги попълните и изпратите. Възможно е също да бъдат изтрити формуляри, които още не са попълнени и/или изпратени.',
                open: false,
            }, {
                question: 'Мога ли да правя промени в отчет, който съм изпратил?',
                answer: 'Не, за съжаление, това не е възможно. Винаги можете обаче да се обърнете към функционалния администратор (обикновено колега, отговарящ за КЗБОС, или експерт по безопасността) от Вашата компания, за да съобщите промените.',
                open: false,
            }, {
                question: 'Какво става с моя отчет, след като го представя?',
                answer: 'Отчетите автоматично се изпращат до лицето, отговарящо за проекта. Конкретното лице зависи от формуляра. ' +
                    'Функционалният администратор (обикновено колега, отговарящ за КЗБОС, или експерт по безопасността) получава екземпляр от формуляра. Освен това ще получите екземпляр от формуляра по имейл.',
                open: false,
            }, {
                question: 'Мога ли да изтрия отчет?',
                answer: 'Да, можете. Не е възможно обаче да го направите чрез приложението. За изтриване на отчет се обърнете към функционалния администратор (обикновено колега отговарящ за КЗБОС, или експерт по безопасността) от Вашата компания.',
                open: false,
            }, {
                question: 'Мога ли да работя офлайн с приложението?',
                answer: 'Да, можете. Можете винаги да попълните формуляр и да го запишете в приложението. Ако формулярът не е попълнен, ще получите съобщение, че не може да бъде изпратен. Формулярът ще бъде записан като чернова. ' +
                    'Черновата няма да бъде изпратена автоматично, след като се свържете с интернет. Трябва да отворите черновата и тогава да я изпратите. ' +
                    'За съжаление, външните потребители не могат да попълнят всички формуляри, ако работят офлайн. Те могат само да съобщават за опасни ситуации офлайн. ' +
                    'За останалите формуляри те трябва да работят онлайн.',
                open: false,
            }, {
                question: 'Могат ли подизпълнители да използват приложението?',
                answer: 'Да, могат. Подизпълнител може да влезе като външен потребител.',
                open: false,
            }, {
                question: 'Мога ли да сменя телефонния си номер?',
                answer: 'Да, можете. Всички служители на ETT могат да сменят телефонните си номера. Можете да направите това в профила си в приложението.',
                open: false,
            }],
        }],
    },
    NOTIFICATION: {
        title: 'Отчет',
        warning: 'Предупреждение',
        close: 'Затваряне',
        open: 'Отваряне',
        error_general: 'Възникна неочаквана грешка.',
        error_retrieving_profile: 'Възникна грешка при извличането на Вашия профил',
        something_wrong_saving: 'Възникна грешка при изпращането на формуляра',
        something_wrong_task: 'Възникна грешка при изпращане на задачата Завършете коментарите или опитайте отново по-късно.',
        something_wrong_logout: 'Възникна грешка при излизане.',
        offline: 'В момента работите офлайн. Поради това в момента тази функция не е достъпна.',
        account_removed_failed: 'Възникна грешка при изтриването на Вашия акаунт Опитайте отново по-късно.',
        inactive_form: 'Този формуляр вече не е валиден. Попълнете отново формуляра.',
        no_access: 'Нямате достъп до WAVE. Обърнете се към администратора,',
        error_message: 'Съобщение за грешка:',
        login_failed: 'Неуспешно влизане',
        error_logged_out: 'За съжаление, възникна грешка при извличане на данните. В момента излизате',
        retrieving_options: 'Извличане на опции/контакти',
        aggregating: 'Обобщени данни',
        update_failed: 'Неуспешно редактиране',
        retrieval_failed: 'Неуспешно извличане',
        error_forms_no_network: 'Не може да се редактират формулярите (няма мрежова връзка) Нов опит за редактиране на формулярите?',
        error_forms_slow_network: 'Не може да се редактират формулярите (бавна мрежова връзка) Нов опит за редактиране на формулярите?',
        error_forms_retrieval_no_network: 'Не може да се извлекат формулярите (няма мрежова връзка) Проверете мрежовата връзка и опитайте отново.',
        error_forms_retrieval_slow_network: 'Не може да се извлекат формулярите (бавна мрежова връзка) Проверете мрежовата връзка и опитайте отново.',
        error_popup_blocked: 'Разрешете изскачащи прозорци, за да влезете',
        image_file_needed: 'Файлът не е валидно изображение',
        route_not_found: 'Неуспешно пренасочване',
        route_not_found_explanation: 'Неуспешно пренасочване към страницата или формуляра. Причината може да е, че е недостъпен за вас, страницата или формулярът вече не съществува или формулярът не е наличен на вашия език.',
    },
    FORGOT_PASSWORD: {
        title: 'Забравена парола',
        subtitle: 'Ако имейл адресът Ви е известен, ще получите съобщение с указания за подновяване на паролата.',
    },
    FORGOT_PASSWORD_FORM: {
        title: 'Въведете имейл адрес',
        email: 'Имейл',
        next: 'Напред',
    },
    NEWS: {
        title: 'Новини',
        subtitle: 'WAVE nieuws berichten',
        search: 'Search by news',
    },
    MEDIA_COMMUNICATION: {
        subtitle: {
            theme: 'Choose a theme',
            subject: 'Choose a topic',
        },
        search: {
            theme: 'Search by theme',
            subject: 'Search by topic',
        },
    },
    PASSWORD_CHANGE: {
        title: 'Смяна на парола',
        current_password: 'Настояща парола',
        password_explanation: 'Паролата трябва да бъде от най-малко 8 знака, да съдържа главна буква, цифра и специален знак',
        new_password: 'Нова парола',
        repeat_new_password: 'Повторете новата парола',
        passwords_dont_match: 'Паролите не съвпадат',
        password_changed: 'Паролата Ви е променена. Трябва да влезете отново',
        current_password_incorrect: 'Въведената парола не съвпада с настоящата',
        confirm: 'Потвърждение',
    },
    QUESTIONNAIRE: {
        part: 'Компонент',
        map_offline: 'Работите офлайн. Картата е достъпна само в режим онлайн.',
        from: 'from',
    },
    QUESTIONNAIRE_HISTORY: {
        title: 'Изпратени формуляри',
        no_forms: 'Все още няма изпратени отчети.',
        submitted_at: 'Подадено на',
        processed_at: 'Обработено на',
        processed_submitted_at: 'Обработено, подадено на',
    },
    QUESTIONNAIRE_REPEAT_QUESTION: {
        part: 'Компонент',
    },
    QUESTIONNAIRE_SENT: {
        title: 'Благодарим',
        subtitle: 'Формулярът се обработва. Копие от формуляра ще Ви бъде изпратено по имейл. Можете да намерите попълнения формуляр и в раздел „Хронология“ в приложението.',
    },
    QUESTIONNAIRE_TASK: {
        created_on: 'Изготвено на:',
        completed_on: 'Попълнено на:',
        place_remark: 'Впишете коментара(ите) си тук',
        remark: 'Коментар(и):',
        task_denied: 'Не е обработено',
        task_completed: 'Обработено',
        are_you_sure:  'Наистина ли искате да зададете “{{statusLabel}}” за тази задача?',
        page_leave: 'Напусни страницата',
        page_leave_explanation: 'Попълнихте полето „коментари“ за задачата. Ако напуснете страницата, тази промяна няма да бъде запазена. Наистина ли искате да напуснете страницата?',
    },
    QUESTIONNAIRE_TASKS: {
        title: 'Задача в процес на изпълнение',
        subtitle: 'Click on a task to execute it',
        planned_forms: 'Планирани формуляри',
        planned_forms_subtitle: 'Below are forms that must be sent by a certain date',
        closed_tasks: 'Изпълнени задачи',
        closed_tasks_subtitle: 'Below are tasks which have been completed',
        closed_on: 'Попълнено на:',
        deadline_passed: 'Изтекъл срок:',
        deadline_at: 'Срокът изтекъл на:',
        created_at: 'Изготвено на:',
    },
    CARD_GAME: {
        question: {
            label: 'Въпрос',
        },
        toolbar: {
            button_label: 'Нова карта',
            close: 'Затваряне',
        },
    },
    ERROR: {
        form: {
            warning: 'Предупреждение',
            not_found: 'Все още няма налични формуляри на вашия език.',
            directIncidentOnly: 'Формуляр „Докладване за инцидент или основна опасност“ в момента няма. Обърнете се към Вашия системен администратор.',
            directComplimentOnly: 'Формулярът не е наличен на вашия език.',
            questionnaireIdToSelect: 'Тази задача в момента не е налична за избраната организационна единица. Изберете друга организационна единица или се обърнете към Вашия системен администратор.',
        },
        no_access: {
            title: 'Без права',
            explanation: 'Вие нямате достъп до тези формуляри. Върнете се на началния екран.',
        },
    },
    REGISTRATION: {
        title: 'Създаване на нов акаунт',
        email: 'Имейл',
        first_name: 'Име',
        sur_name: 'Фамилия',
        mobile_phone: 'Мобилен номер',
        password: 'Парола',
        password_explanation: 'Паролата трябва да бъде от най-малко 8 знака, да съдържа главна буква, цифра и специален знак',
        repeat_password: 'Повторете паролата',
        passwords_dont_match: 'Паролите не съвпадат',
        approve: 'Давам съгласие за',
        privacy: 'Политика за поверителност',
        create_account: 'Създаване на акаунт',
        account_exists: 'Вече има акаунт, свързан с този имейл адрес',
        error_something_wrong: 'Възникна грешка при регистрацията.',
    },
    REGISTRATION_COMPLETE: {
        title: 'Потвърдете своя имейл адрес',
        subtitle: 'Благодарим Ви за създаването на акаунт. След няколко секунди ще получите имейл с искане да потвърдите електронния си адрес',
    },
    REGISTRATION_SMS: {
        title: 'Потвърждаване на телефонен номер',
        subtitle: 'Въведете тук кода за потвърждаване, който получихте с текстово съобщение. Този код ще бъде изпратен до определения телефонен номер.',
        verification_code: 'Код за потвърждаване',
        verify: 'Проверете кода за потвърждаване',
        incorrect_code: 'Проверете кода за потвърждаване',
        error_something_wrong: 'Възникна грешка при потвърждаването.',
    },
    SELECT_QUESTIONNAIRE: {
        open_forms: 'Формулярите още не са попълнени',
        open_forms_subtitle: 'Click on a form to continue',
        new_form: 'New form',
        new_form_subtitle: 'Click to start a new form',
        planned_forms: 'Планирани формуляри',
        available_forms: 'Налични формуляри',
        workplace_forms: 'Проверка на работното място',
        retrieve_forms: 'Извличане на формуляри',
        retrieve_form: 'Извличане на формуляр',
        choose_action: 'Изберете действие',
        open: 'Отваряне',
        upload: 'Поставете онлайн',
        download_and_open: 'Изтеглете и отворете формуляр',
        remove: 'Изтрий',
        something_went_wrong: 'Възникна грешка при извличане на формулярите',
    },
    SELECT_STRUCTURE: {
        choose_project: 'Изберете проект',
        choose_business_unit: 'Изберете бизнес единица',
        no_organisations: 'Няма налични организации.',
        search_on: 'Търсене по',
        choose_your: 'Select your',
        organisation: 'компания',
        project: 'проект',
        projects: 'Проекти',
        business_unit: 'бизнес единица',
        business_units: 'Бизнес единици',
    },
    SEND_QUESTIONNAIRE: {
        title: 'Искате ли да изпратите формуляра?',
        description: 'Изпращайки го, вече не можете да промените нищо в този изпратен формуляр.',
        account_blocked: 'Формулярът не може да се изпрати, докато акаунтът не бъде одобрен',
        offline: 'Работите офлайн и поради това не можете да изпратите формуляра. Изпратете формуляра, след като отново сте онлайн',
        something_went_wrong: 'Възникна грешка при изпращането на формуляра',
        confirm: 'Да, изпрати',
        cancel: 'Не, обратно',
    },
    SCAN_QR: {
        incorrect_result: 'Неправилен QR код. Сканирайте правилен WAVE QR код.',
        no_result: 'Не е сканиран QR код.',
        no_permission: 'Приложението няма достъп до камерата. Дайте разрешение на приложението, преди да сканирате QR код.',
        try_again: 'Сканирайте отново',
    },
    COMPONENTS: {
        attachments_remarks: {
            add: 'Добавете',
            place_remark: 'Впишете коментара(ите) си тук',
            max_attachments_reached: 'Достигнат е максималният брой приложения.',
            note: 'Note',
            picture: 'Picture',
        },
        checkbox: {
            multiple_choices_possible: 'Възможни са няколко опции',
            no_choices_selected: 'Не е(са) избрана(и) опция(и).',
        },
        checkbox_searchable: {
            selected_choices: 'Избрана(и) опция(и)',
            no_choices_found: 'Не е(са) намерена(и) опция(и).',
            visible_choices: 'Виждат се опциите {{maxListChoices}} от {{filteredChoices}}.',
            limit_choices: 'Ограничете списъка опции чрез търсене.',
        },
        lat_long: {
            current_location: 'Използвайте настоящото местоположение',
            or: 'или',
            city: 'Град/село',
            address: 'Адрес',
            invalid_address: 'Адресът не е валиден.',
        },
        manual_choice: {
            new_choice: 'Въведете нова опция',
            choice_exists: 'Тази опция вече съществува.',
        },
        photo: {
            subtitle: 'Добавете снимки, като щракнете върху знака плюс',
            gallery: 'Фото галерия',
            drop_explanation: 'Кликнете, плъзнете или поставете снимки тук, за да ги добавите',
            max_reached: 'Достигнат е максималният брой снимки, които могат да бъдат прикачени.',
            take_a_photo: 'Направи снимка',
            upload_from_gallery: 'Качване от галерия',
        },
        questionnaire_footer: {
            chapters: 'Глави',
            previous: 'Предишни',
        },
        questionnaire_overlay: {
            help_text: 'След като отговорите на въпроса, щракнете върху „Напред“, за да преминете към следващия въпрос.',
        },
        radio: {
            choose: 'Направете избор',
            no_choice_selected: 'Не е избрана опция.',
        },
        radio_searchable: {
            selected_choice: 'Избрана опция',
            no_choice_found: 'Не са намерени опции',
            visible_choices: 'Виждат се опциите {{maxListChoices}} от {{filteredChoices}}.',
            limit_choices: 'Ограничете списъка опции чрез търсене.',
        },
        searchable_choices: {
            search: 'Търсене',
        },
        select_concept: {
            last_change: 'Последно редактирано:',
        },
        select_item: {
            submit_before: 'Да се изпрати преди ',
        },
    },
};
